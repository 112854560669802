body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

}

body .App{
  background-color: rgb(15 23 42);
}

.App .h1, .App .h2, .App .h3, .App .h4, .App .h5, .App .h6, .App h1, .App h2, .App h3, .App h4, .App h5, .App h6{
  color: #fff;
}

.backButton{
  position: relative;
  z-index: 99999;
}

.page-header-image{
  background-image: url("images/back.jpg");
}


.cvmode .aos-animate {
  /*CSS transitions*/
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
  /*CSS transforms*/
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
  /*CSS animations*/
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}